
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

@import '../../node_modules/bootstrap/dist/css/bootstrap.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

// DataTables
@import "../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "../../node_modules/datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
@import "../../node_modules/datatables.net-responsive-dt/css/responsive.dataTables.min.css";
@import "../../node_modules/datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";



//import admin lte css
@import '../../node_modules/admin-lte/dist/css/adminlte.min.css';
//Font Awesome Icons
@import '../../node_modules/admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '../../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css';
//overlayScrollbars
@import '../../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';

//@import '~admin-lte/dist/css/skins/skin-blue.min.css';
//@import '~admin-lte/plugins/font-awesome/css/font-awesome.min.css';
//@import '~font-awesome/css/font-awesome.min.css';

//@import '~admin-lte/plugins/iCheck/flat/blue.css';
//@import '~admin-lte/plugins/jvectormap/jquery-jvectormap-1.2.2.css';
//@import '~admin-lte/plugins/bootstrap-wysihtml5/bootstrap3-wysihtml5.min.css';

//Ionicons
//@import "../../node_modules/ionicons/dist/scss/ionicons";

@import "../../node_modules/bootstrap-colorpicker/src/sass/colorpicker";
@import "../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.min.css";
@import "../../node_modules/formeo/dist/formeo.min.css";

.error {
  color: red;
}
.dataTable {
  width: 100% !important;
}
.disabled-color{
  background-color: #dd4b3924 !important
}
.green-color{
  background-color: #ccead2 !important
}
.yellow-color{
  background-color: #f3ec0c59 !important
}
#dt_show_deleted_message{
  display: none;
}
div.odd{
  padding-top: 10px;
  padding-bottom: 10px;
  background-color:rgba(0,0,0,.05)
}
div.even{
  padding-top: 10px;
  padding-bottom: 10px;
}
div.dt-buttons {
  float: right;
}

.dataTable thead tr th {
  background-color: #ecf0f5;
}
.dataTable tr.even td {
  background-color: #fbfafa;
}
.dataTable tr.even.disabled-color td {
  background-color: #dd4b3924 !important
}
.dataTable tr.even.green-color td {
  background-color: #ccead2 !important
}
.dataTable tr.even.yellow-color td {
  background-color:  #f3ec0c59 !important
}
.dataTable tr.odd.disabled-color td {
  background-color: #dd4b3924 !important
}
.dataTable tr.odd.green-color td {
  background-color: #ccead2 !important
}
.dataTable tr.odd.yellow-color td {
  background-color:  #f3ec0c59 !important
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.bg-info {
  background-color: #343A40 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #343A40 !important;
}
